<template>
  <div class="page-dealer bg-main">
    <div class="box-top" v-if="role.isAfDealerBoth && role.isReport">
      <div class="fixed">
        <div class="box-jump" @click="$router.push({name: 'afDealerAnalysis'})">售后平台
          <img src="@/assets/i_change.png"/>
        </div>
      </div>
    </div>
    <div class="box-info-temp" style="padding: 0.5rem 0.75rem; font-size: 0.62rem;">通知:{{boardTip}}</div>
    <div class="box-date">
      <DataSearch :qrTitle="role.isReport? '数据统计':'数据平台'" :dateQuery="ruleForm" @change="changeDate"/>
    </div>

    <h3 class="title">
      <strong>接待总览</strong>
      <span>
          <el-radio-group v-model="inNum.r1" @change="getTotal()">
            <el-radio v-for="item in inNumList" :key="item" :label="item"></el-radio>
          </el-radio-group>
     </span>
    </h3>
    <div class="box-result sec">
      <div class="score" v-for="(item, idx) in totalAccount" :key="idx">
        <div class="num"><strong>{{item.value}}</strong></div>
        <div class="name">{{item.title}}({{item.unit}})</div>
      </div>
    </div>

    <h3 class="title">
      <strong>接待量趋势</strong>
      <span>
          <el-radio-group v-model="dateType" @change="getTrend">
            <el-radio v-for="item in dateList"
              :key="item" :label="item" style="margin-right: 10px;"></el-radio>
          </el-radio-group>
     </span>
    </h3>

    <div class="sec">
      <div id="chartTrend" style="width: 100%; height: 300px;padding-top: 0.5rem;"></div>
    </div>

    <h3 class="title">
      <strong>话术标签</strong>
      <span>
          <el-radio-group v-model="inNum.r4" @change="getLabel()">
            <el-radio v-for="item in inNumList" :key="item" :label="item"></el-radio>
          </el-radio-group>
     </span>
    </h3>
    <div class="sec">
      <div class="box-tag">
        <span :class="tagTab == 1 ? 'sel' : ''" @click="changeTagTab(1)">
          开口率
        </span>
        <span :class="tagTab == 2 ? 'sel' : ''" @click="changeTagTab(2)">
          开口次数
        </span>
      </div>
      
      <div class="box-filter">
        <div class="item" v-for="(item,idx) in tagData" :key="idx" @click="changeTag(item)">
          <span :class="item.title === curTag.title ? 'i sel': 'i'"></span>
          {{item.title}}
        </div>
      </div>
      <div id="chartTag" style="width: 100%; height: 300px;"></div>
    </div>

    <h3 class="title">
      <strong>检核分析</strong>
      <span>
          <el-radio-group v-model="inNum.r2" @change="getChart()">
            <el-radio v-for="item in inNumList" :key="item" :label="item"></el-radio>
          </el-radio-group>
     </span>
    </h3>
    <div class="sec">
      <div id="chart" style="width: 100%; height: 300px;padding-top: 0.5rem;"></div>
    </div>

    <h3 class="title">
      <strong>接待环节</strong>
    </h3>
    <div style="display: flex; padding: 0 0.75rem; align-items: center;">
      <span class="sort-step" @click="onSort(scoreOrder == 1 ? 0 : 1)">
          {{['低分','高分'][scoreOrder]}}
          <span class="icon-sort">
            <i class="el-icon-caret-top" :style="`color: ${scoreOrder==0 ? '#1890ff' : ''}`"></i>
            <i class="el-icon-caret-bottom" :style="`color: ${scoreOrder==1 ? '#1890ff' : ''}`"></i>
          </span>
        </span>
      
      <span style="flex:1; text-align: right;">
          <el-radio-group v-model="inNum.r4" @change="getStep()">
            <el-radio v-for="item in inNumList" :key="item" :label="item"></el-radio>
          </el-radio-group>
     </span>
    </div>
    <div class="sec">
      <div class="box-filter">
        <div class="item" v-for="(item,idx) in stepData" :key="idx" @click="changeStep(item)">
          <span :class="item.title === curStep.title ? 'i sel': 'i'"></span>
          {{item.title}}
        </div>
      </div>
      <div id="chartStep" style="width: 100%; height: 300px;"></div>
    </div>

    <h3 class="title">
      <strong>接待员工检核</strong>
    </h3>
    <div class="sec" style="padding:0 0 0.5rem 0; margin-bottom: 0.5rem">
      <ul class="table staff">
        <li class="header">
          <span class="c1">接待人员</span>
          <span class="c2">接待量(次)</span>
          <span class="c4">平均接待时长(分)</span>
          <span class="c3">接待质量得分</span>
        </li>
        <div v-if="!stafftableData.length" class="empty">暂无数据</div>
        <li v-else v-for="(item, idx) in stafftableData" :key="idx"
          @click="$router.push({name: 'dealerStaff', query: {id:item.id, ...ruleForm}})"
          >
          <span class="c1">{{item.seller}}</span>
          <span class="c2">{{item.count}}</span>
          <span class="c4">{{item.time}}</span>
          <span class="c3">{{item.score}}分</span>
        </li>
      </ul>
    </div>

    <h3 class="title">
      <strong>接待客户明细</strong>
    </h3>
    <div class="sec" style="padding:0 0 0.5rem 0; margin-bottom: 0.5rem">
      <ul class="table custom">
        <li class="header">
          <span class="c1">客户手机号</span>
          <span class="c5">进店情况</span>
          <span class="c2">接待时长(分)</span>
          <span class="c5">接待人员</span>
          <span class="c3">接待质量得分</span>
        </li>
        <div v-if="!tableData.length" class="empty">暂无数据</div>
        <li v-else v-for="(item, idx) in showTableData" :key="idx"
          @click="$router.push({name: 'receptionShow', query: {id:item.id}})"
          >
          <span class="c1">{{item.name}}</span>
          <span class="c5">{{item.in_num}}</span>
          <span class="c2">{{item.length}}</span>
          <span class="c5">{{item.seller}}</span>
          <span class="c3">{{item.score}}分</span>
        </li>
      </ul>
      
      <div v-if="tableData.length >= 8" style="text-align: center; padding: 1rem 0; color: #999" @click="jumpToList('checkDetailList')">点击查看更多 <i class="el-icon-arrow-right"></i></div>
      <!--
      <div v-if="tableData.length > maxLen" id="list"
        style="text-align: center; padding-top: 10px; font-weight: 400;">
        <a @click="toggleExpand"
          style="color: #377EEA; font-size: 13px; display: flex; justify-content: center;">
          <a-icon :type="isExpanded ? 'caret-up' : 'caret-down'"
            style="font-size: 18px; margin-right: 2px;"/>
            <span>{{isExpanded ? '收起' : '展开'}}</span>
        </a>
      </div>
      -->
      
    </div>

    
    <h3 class="title">
      <strong>试驾员工检核</strong>
    </h3>
    <div class="sec" style="padding:0 0 0.5rem 0; margin-bottom: 0.5rem">
      <ul class="table staff">
        <li class="header">
          <span class="c1">试驾顾问</span>
          <span class="c2">试驾量（次）</span>
          <span class="c4">平均试驾时长（分）</span>
          <span class="c3">试驾得分</span>
        </li>
        <div v-if="!staffDriveData.length" class="empty">暂无数据</div>
        <li v-else v-for="(item, idx) in staffDriveData" :key="idx"
          @click="$router.push({name: 'driveStaff', query: {id:item.id, ...ruleForm}})"
          >
          <span class="c1">{{item.seller}}</span>
          <span class="c2">{{item.count}}</span>
          <span class="c4">{{item.time}}</span>
          <span class="c3">{{item.score}}分</span>
        </li>
      </ul>
    </div>

    <h3 class="title">
      <strong>试驾客户明细</strong>
    </h3>
    <div class="sec" style="padding:0 0 0.5rem 0; margin-bottom: 0.5rem">
      <ul class="table custom">
        <li class="header">
          <span class="c1">客户手机号</span>
          <span class="c2">试驾时长（分）</span>
          <span class="c5">试驾顾问</span>
          <span class="c3">试驾质量得分</span>
        </li>
        <div v-if="!driveData.length" class="empty">暂无数据</div>
        <li v-else v-for="(item, idx) in driveData" :key="idx"
          @click="$router.push({name: 'receptionShow', query: {id:item.id}})"
          >
          <span class="c1">{{item.user_mobile}}</span>
          <span class="c2">{{item.length}}</span>
          <span class="c5">{{item.seller}}</span>
          <span class="c3">{{item.score}}分</span>
        </li>
      </ul>
      <div v-if="driveData.length >= maxLen" style="text-align: center; padding: 1rem 0; color: #999" @click="jumpToList('driveList')">点击查看更多 <i class="el-icon-arrow-right"></i></div>
      <!--
      <div v-if="showMore" style="text-align: center; padding: 1rem 0; color: #999" @click="getDriveList()">点击加载更多 <i class="el-icon-arrow-down"></i></div>
      <div v-if="driveData.length > maxLen" id="list"
        style="text-align: center; padding-top: 10px; font-weight: 400;">
        <a @click="toggleDriveExpand"
          style="color: #377EEA; font-size: 13px; display: flex; justify-content: center;">
          <a-icon :type="isDriveExpanded ? 'caret-up' : 'caret-down'"
            style="font-size: 18px; margin-right: 2px;"/>
            <span>{{isDriveExpanded ? '收起' : '展开'}}</span>
        </a>
      </div>
      -->
    </div>
    <NavBar tab="统计分析"></NavBar>
  </div>
</template>

<script>
import Vue from 'vue'
import { postForm, fetchGet } from '@/api'
import NavBar from '@/components/navbarDealer.vue';
import DataSearch from '@/components/dataSearch.vue';
import * as echarts from 'echarts'
import { tagOpts, radarOpts, stepOpts, lengthTrendOpts } from '@/utils/chart'
import { getFormatDate } from "@/dbCommon/utils";
import {getRole} from '@/utils/getRole';


export default {
  data() {
      return {
        role: getRole(),
        scoreOrder: 1,
        selPop: {
          target_model: false,
          user_level: false,
        },
        selObj: {
          target_model: '',
          user_level: '',
        },
        tagData: [],
        tagTab: 1,
        curTag: {},
        totalAccount: [],
        stafftableData: [],
        stepData: [],
        curStep: {},
        tableData: [],
        carModelOpts: [],
        userLevelOpts: [],
        driveData: [],
        staffDriveData: [],
        inNum: {
          r1: '首次进店',
          r2: '首次进店',
          r3: '首次进店',
          r4: '首次进店',
        },
        inNumList: ['首次进店', '再次进店'],
        dateList: ['日趋势', '周趋势', '月趋势'],
        dateType: '日趋势',
        ruleForm: {
          date_type: '今日'
        },
        online: false,
        electricity: 0,
        maxLen: 10,
        isExpanded: true,
        isDriveExpanded: true,
        showTableData: [],
        showDriveData: [],
        showMore: false,
        pageNum: 1,
        pageSize: 10,
        boardTip: '由于Q1模型变更所导致的系统升级，4S&卫星店【接待质量得分】暂时无法使用（DTS模型不变不受影响）。新功能预计将于1月底上线，在此期间除【接待质量得分】外的其他后台功能均可正常使用，还请知悉。',
      }
  },
  components:{
    NavBar,
    DataSearch,
  },
  methods : {
    jumpToList(name) {
      const map = {
        '今日': 'yesterday',
        '本周': 'week',
        '本月': 'month',
        '上月': 'lastMonth',
        '自定义': 'custom',
       }
      const {date_type, ...rest} = this.ruleForm;
      let q = {};
      if (date_type == '自定义') {
        q = {
          ...rest,
          date_type: map[date_type],
        }
      } else {
        q = {
          date_type: map[date_type],
        }
      }
      this.$router.push({name, query: {...q}});
    },
    toggleDriveExpand() {
      this.isDriveExpanded = !this.isDriveExpanded;
      const l = [...this.driveData];
      this.showDriveData =  this.isDriveExpanded? l : l.slice(0, this.maxLen);
      //const dom = document.getElementById('list');
      //dom && dom.scrollIntoView();
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      const l = [...this.tableData];
      this.showTableData =  this.isExpanded? l : l.slice(0, this.maxLen);
      //const dom = document.getElementById('list');
      //dom && dom.scrollIntoView();
    },
    
    changeDate(v) {
      this.ruleForm = {
        ...this.ruleForm,
        ...v,
      }
      this.$router.replace({name: 'dealerIndex', query: {...this.ruleForm}});
      this.pageNum = 1;
      this.loadData();
    },
    getTableList(v) {
      this.getStaffList(v);
      this.getList(v);
    },
    selDateType(v) {
      this[v.key] = v.value;
    },
    changeTagTab(l) {
      this.tagTab = l;
      this.changeTag(this.curTag, l);
    },
    changeTag(item) {
      this.curTag = item || {};
      const d = this.curTag.dataList || [];
      const labelArr = d.map(o => o.label);
      const timesArr = d.map(o => o.value);
      const rateArr = d.map(o => o.rate);
      const valueArr = this.tagTab == 2 ? timesArr : rateArr;
      this.tagChart.clear();
      this.tagChart.setOption(tagOpts({
        labelArr,
        valueArr,
        tagTab: this.tagTab
      }));
    },
    changeStep(item) {
      this.curStep = item || {};
      const {dataList} = this.curStep || {};
      const list = (dataList || []).sort((a, b) => {
        if (this.scoreOrder == 1) {
          return a.value - b.value;
        } else {
          return b.value - a.value;
        }
      });
      this.curStep.dataList = [...list];
      const d = this.curStep.dataList || [];
      const labelArr = d.map(o => o.label);
      const valueArr = d.map(o => o.value);
      this.stepChart.clear();
      this.stepChart.setOption(stepOpts({
        labelArr,
        valueArr,
      }));
    },
    onSort(i) {
      this.scoreOrder = i;
      this.changeStep(this.curStep);
    },
    onSel(obj) {
      this.selPop = {};
      this.selObj = {
        ...this.selObj,
        ...obj
      }
      this.getList();
    },
    onToggle(k) {
      this.selPop = {
        [k]: !this.selPop[k],
      }
      setTimeout(() => {
        const dom = document.getElementById(this.selObj[k]);
        dom && dom.scrollIntoView({
          behavior: 'smooth',
        });
      }, 0);
    },
    getUserLevel() {
      postForm('/api/option/deal_edit', {field: 'user_level'}).then(res => {
        if (res.status === 1) {
          this.userLevelOpts = res.data || [];
        }
      })
    },
    getCarModel() {
      postForm('/api/option/target_model').then(res => {
        if (res.status === 1) {
          this.carModelOpts = res.data || [];
        }
      })
    },
    getTotal(dq={}) {
      const {ruleForm} = this;
      fetchGet('h5/manager/receptionOverview', {
        ...ruleForm,
        ...dq,
        in_num: this.inNum.r1,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }

          const rest = res.data.receptionOverview;
          this.totalAccount = [
            {value: rest.total, title: rest.total_title, unit: rest.total_unit},
            {value: rest.allTime, title: rest.allTime_title, unit: rest.allTime_unit},
            {value: rest.avgTime, title: rest.avgTime_title, unit: rest.avgTime_unit},
            {value: rest.score, title: rest.score_title, unit: rest.score_unit},
          ];

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getTrend() {
      const {ruleForm} = this;
      fetchGet('h5/manager/receptionTrendListNew', {
        ...ruleForm,
        date_type: this.dateType,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.trendList = res.data.receptionTrendList || [];
        if (!this.trendList.length) {
          return;
        }
        const itemList = this.trendList.map((o) => ({
            title: o.title,
            unit: o.unit,
            labelArr: o.dataList.map(o => o.label),
            valueArr: o.dataList.map(o => o.value),
        }));
        this.trendChart.clear();
        this.trendChart.setOption(lengthTrendOpts(itemList));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getChart(dq={}) {
      const {ruleForm} = this;
      fetchGet('h5/manager/checkAnalyseList', {
        ...ruleForm,
        ...dq,
        in_num: this.inNum.r2,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.analyzeList = res.data.checkAnalyseList;
        const tags = this.analyzeList;
        const labels = tags.map(o => o.title);
        const values = tags.map(o => parseFloat(o.score));
        this.myChart.clear();
        this.myChart.setOption(radarOpts({labelArr: labels, valueArr: values}));

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getStep(dq={}) {
      const {ruleForm} = this;
      fetchGet('h5/manager/receptionLinkList', {
        ...ruleForm,
        ...dq,
        in_num: this.inNum.r4,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
          this.stepData = res.data.receptionLinkList || [];
          this.changeStep(this.stepData[0]);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getLabel(dq={}) {
      const {ruleForm} = this;
      fetchGet('h5/manager/tagList', {
        ...ruleForm,
        ...dq,
        in_num: this.inNum.r4,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
          this.tagData = res.data.tagList || [];
          this.changeTag(this.tagData[0]);

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getStaffList(dq={}) {
      const {ruleForm} = this;
      fetchGet('h5/manager/receptionStaffCheckList', {
        ...ruleForm,
        ...dq,
        //in_num: this.inNum.r3,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.stafftableData = res.data.receptionStaffCheckList || [];

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getList(dq={}) {
      const {ruleForm, order_by} = this;
      const {target_model, user_level} = this.selObj;
      fetchGet('h5/manager/receptionCustomerList', {
        ...ruleForm,
        ...dq,
        pageNum: 1,
        pageSize: this.pageSize,
        //target_model,
        //user_level,
        //order_by,
        //in_num: this.inNum.r3,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.tableData = res.data.receptionCustomerList || [];
        this.toggleExpand();

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
    getStaffDriveList(dq={}) {
      const {ruleForm} = this;
      fetchGet('h5/manager_drive/getStaffCheckList', {
        ...ruleForm,
        ...dq,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.staffDriveData = res.data.receptionStaffCheckList || [];

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getDriveList(num) {
      const {ruleForm, order_by} = this;
      const pageNum = num || this.pageNum;
      fetchGet('h5/manager_drive/getCustomerList', {
        ...ruleForm,
        pageNum: pageNum,
        pageSize: this.pageSize,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
          const list = res.data.list || [];
          if (pageNum === 1) {
            this.driveData = list;
          } else {
            this.driveData = [...this.driveData, ...list];
          }

          this.isEmpty = !this.driveData.length;
          this.pageNum = Number(res.pageNum) + 1;
          const total = Number(res.total);
          this.showMore = total > this.driveData.length;

          //this.toggleDriveExpand();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    loadData() {
      this.getTotal();
      this.getChart();
      this.getLabel();
      this.getStep();
      this.getStaffList();
      this.getList();
      this.getStaffDriveList();
      this.getDriveList();
    },
  },
  created() {
    /*
    Vue.ls.set('SHOW_OPEN_TIP', true);
    const showOpenTip = Vue.ls.get('SHOW_OPEN_TIP');
    if (showOpenTip) {
      this.$alert(this.boardTip, '通知', {
        customClass: 'box-alert',
        showClose: false,
        confirmButtonText: '知道了',
        callback: action => {
          Vue.ls.remove('SHOW_OPEN_TIP');
        }
      });
    }
    */
    /*
    const {start_date: s, end_date: e} = this.$router.currentRoute.query || {};
    const { start_date, end_date } = getFormatDate('yesterday');
    const isYestoday = start_date == s && end_date == e;

    if (isYestoday) {
      this.ruleForm = {
        ...this.ruleForm,
        start_date: s,
        end_date: e,
      }
    } else if (s || e) {
     this.ruleForm = {
        ...this.ruleForm,
        date_type: '自定义',
        start_date: s,
        end_date: e,
      }
    }
    */
    const q = this.$router.currentRoute.query || {};
    this.ruleForm = {
        ...this.ruleForm,
        ...q,
    }
    this.loadData();
    this.getTrend();
  },
  mounted () {
    const domA = document.getElementById('chart');
    this.myChart = echarts.init(domA);

    const dTrend = document.getElementById('chartTrend');
    this.trendChart = echarts.init(dTrend);

    const dTag = document.getElementById('chartTag');
    this.tagChart = echarts.init(dTag);

    const dStep = document.getElementById('chartStep');
    this.stepChart = echarts.init(dStep);
  },
}
</script>

<style lang="less">

.page-dealer {

  .el-radio__inner {
    background: url('~@/assets/radio.png') center center no-repeat;
    background-size: cover;
    border: 0;
    &::after {
      display: none;
    }
  }
  .el-radio__input.is-checked .el-radio__inner {
    background: url('~@/assets/radio_sel.png') center center no-repeat;
    background-size: cover;

  }
  .el-radio-group {
      .el-radio__input {
        width: 20px;
      }
      .el-radio__label {
        padding-left: 0;
        color: #000;
      }
  }
  .sort-step {
    border-radius: 0.3rem;
    padding: 0.3rem 1rem;
    background: #fff;
    color: #377EEA;
    display: flex;
    white-space: nowrap;
    align-items: center;
    font-size: 0.7rem;
    .icon-sort {
      margin-left: 0.2rem;
      display: flex;
      flex-direction: column;
      font-size: 12px;

      i {
        color: #bfbfbf;
        margin: -2px 0;
      }
    }
  }
  .table {
    color: #000;
    font-size: 0.6rem;

    &.staff {
      .c2 {
        text-align: center;
      }
      .c4 {
        min-width: 5.8rem;
        text-align: center;
      }
    }
    &.custom {
      .c1 {
        min-width: 3.7rem;
      }
      .c2 {
        min-width: 3.8rem;
        text-align: center;
      }
    }
    .empty {
      min-height: 5rem;
      line-height: 5rem;
      text-align: center;
      color: #ccc;
    }
    li {
      display: flex;
      background: #fff;
      align-items: center;

      >span {
        flex: 1;
        line-height: 1.3;
        padding: 0.5rem 0.2rem;
        word-break:break-all;
        position: relative;

        &.sort {
          display: flex;
          white-space: nowrap;
          align-items: center;
        }
      }
      .box-sort {
        display: flex;
        flex-direction: column;
        font-size: 12px;

        i {
          color: #bfbfbf;
          margin: -2px 0;
        }
      }
      
      .box-sel {
        white-space: nowrap;
        transition: all .3s;
      }
      .box-opts {
        position: absolute;
        left: 0.2rem;
        border: 1px solid #e4e7ed;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 1px 10px 0 #ccc;
        box-sizing: border-box;
        max-height: 12rem;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 1;
        margin-top: 0.2rem;
        a {
          padding: 0 0.2rem;
          display: block;
          white-space: nowrap;
          line-height: 1.8;
          min-width: 3rem;

          &:hover {
            background: #f5f7fa;
          }

          &.sel {
              color: #409eff;
              font-weight: 700;
          }
        }
      }

      &.header {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        font-weight: 500;
        background: #EBF2FD;
        padding: 0.2rem 0;
        align-items: flex-start;
        white-space: nowrap;
      }
      &:nth-child(even) {
        background: #F5F5F5;
      }
    }
  }
  h3.title {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    font-size: 0.8rem;
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
    display: flex;

    >span {
      flex: 1;
      text-align: right;
      .el-radio__input {
        width: 20px;
      }
      .el-radio__label {
        padding-left: 0;
        color: #000;
      }
    }
  }

  .box-filter {
    padding: 1rem;
    .item {
      display: inline-block;
      margin-right: 0.5rem;
      padding: 0.25rem 0;
      cursor: pointer;
      .i {
        width: 1rem;
        height: 0.25rem;
        background: #e8e8e8;
        display: inline-block;
        margin-right: 0.25rem;
        vertical-align: middle;

        &.sel {
          background: #6F95FC;
        }
      }
    }
  }
  
  .box-tag {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 0.7rem;
      line-height: 2rem;
      width: 47%;
      text-align: center;
      border: 1px solid #F5F5F5;
      border-radius: 10px;
      background: #F5F5F5;

      &.sel {
        border: 1px solid #1890ff;
        color: #377EEA;
        background: #fff;
      }
    }
  }

  .box-result {
    padding: 0;
    display: flex;
    flex- wrap: wrap;
    .score {
      min-width: 50%;
      text-align: center;
      border-top: 1px solid #EFEFEF;
      border-left: 1px solid #EFEFEF;
      padding: 1rem 0;

      &:nth-child(1), &:nth-child(2) {
        border-top: 0;
      }
      &:nth-child(odd) {
        border-left: 0;
      }

      .num {
        font-size: 0.9rem;
      }
      .name {
        font-size: 0.6rem;
        color: #788798;
      }
    }
  }

.box-top {
  height: 44px;
  position: relative;

  .fixed {
    height: 44px;
    line-height: 44px;
    top: 0;
    width: 100%;
    max-width: 750px;
    z-index: 1000;
    position: fixed;
    background: #F2F4F5 url(/img/bg_main.f9ed88c6.png) top center no-repeat;
    background-size: 100% auto;
  }

  .box-jump {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #377EEA;
    width: 130px;
    margin: auto;

    img {
      height: 15px;
    }
  }
}
  .box-date {
    background: url('~@/assets/bg_main.png') top center no-repeat;
    background-size: 100% auto;
    padding: 1rem 0.75rem 0.5rem 0.75rem;
  }

}
</style>

