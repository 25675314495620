<template>
    <div>
      <div class="sec" style="margin-top: 0">
        <div class="box-score">
          <div class="round" :style="`transform: rotate(${score * 1.8}deg);`"> <i></i> </div>
          <div class="ruling">
            <i v-for="(item, idx) in rules" :key="idx" :style="`transform: rotate(${idx * 18}deg);`"></i> 
          </div>
          <div class="bg-gray"></div>
          <div class="mask"></div>
          <div class="score">
            <div><strong>{{score}}</strong> 分</div>
            <div style="color: #969799; font-size: 0.6rem;">本次接待得分</div>
          </div>
        </div>
        <div class="box-progress">
            <div class="score" v-for="(item, idx) in checkResult" :key="idx">
              <div class="circle">
                <el-progress type="circle"
                  :class="item.scoreResult > 0 && item.scoreResult < 101 ? 'c'+idx : ''"
                  :width="45"
                  :stroke-width="4"
                  :percentage="item.scoreResult"
                  :color="color[idx]"
                  :format="p => `${p> 100 ? '-' : p + '%'}`"
                  ></el-progress>
              </div>
              <div class="line">
                <div class="title">{{item.title}}</div>
                <el-progress
                  :percentage="item.scoreResult"
                  :stroke-width="6"
                  :color="color[idx]"
                  :format="p => `${p> 100 ? '-' : p + '分'}`"
                  ></el-progress>
              </div>
            </div>
          </div>
      </div>
      <div class="box-hit">
        <div class="box-item" v-for="(item) in checkResult" :key="item.id">
            <div class="title">{{item.title}}</div>
            <div class="sec">
            <div class="box-list" v-for="(list, idx) in item.hitResult" :key="idx">
              <div class="sub-title">
                {{list.title}}
                <img v-if="list.state" src="@/assets/i_right.png"  class="i-check"/>
              </div>
              <div class="box-tag">
                <span v-for="(tag) in list.children">
                <span v-if="tag.type == 1" :class="tag.state ? 'tag sel': 'tag btn'"
                  @click="onResultTag(tag.time)"
                  :style="{cursor: tag.state ? 'pointer' : ''}"
                  :key="tag.title">
                  {{tag.title}}
                </span>
                <span v-else class="tag default"
                  @click="setStartTime(tag.time)"
                  :style="{cursor: tag.state ? 'pointer' : ''}"
                  :key="tag.title">
                  {{tag.title}}
                  <img v-if="tag.state" src="@/assets/i_right.png"  class="i-check"/>
                  <!--
                  <img v-else src="@/assets/i_error.png"  class="i-check"/>
                  -->
                </span>
                </span>
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import {getRole} from '@/utils/getRole'
import { postForm } from '@/api'
const color = ['#377EEA','#FAC15C','#11C16F','#ED5A54','#54BFED', '#377EEA','#FAC15C','#11C16F','#ED5A54','#54BFED']

export default {
  data() {
      return {
        color,
        id: '',
        curTab: 't1',
        detail: {},
        checkResult: [],
        score: 0,
        rules: [...new Array(10)]
      }
  },
  methods: {
  },
  created() {
    const {id, tab} = this.$router.currentRoute.query || {};
    postForm('/h5/seller/detail', {id}).then(res => {
        if (res.status === 1) {
          const {checkResultList, userInfo={}} = res.data;
          this.checkResult = checkResultList || [];
          this.score = userInfo.score;
          this.business = userInfo.business;
        } else {
          this.$message.error(res.msg);
        }
      });
  }
}
</script>

<style lang="less">

.page-reception-show {

  .box-score {
    margin: auto;
    height: 9rem;
    width: 15rem;
    position: relative;
    overflow: hidden;

    .bg-gray {
      background: #fff;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 12.5rem;
      height: 12.5rem;
      transform: translate(-50%, 50%);
      border-radius: 50%;
      border: 0.5rem solid #F5F5F5;
    }

    .ruling {
      position: absolute;
      bottom: -7.5rem;
      left: 0;
      width: 15rem;
      height: 15rem;

      i {
        position: absolute;
        top: 50%;
        left: 0.4rem;
        right: 0.4rem;
        border-radius: 4px;
        height: 0.1rem;
        display: block;
        background: #fff;
        background: linear-gradient(90deg, #fff, transparent 1rem);
      }
    }

    .round {
      position: absolute;
      bottom: -7.5rem;
      left: 0;
      transform-origin: center;
      width: 15rem;
      height: 15rem;
      i {
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(#FAC15C 50%, #377EEA 50%);
        border-radius: 50%;
        border: 0.3rem solid #F5F5F5;
      }
    }
    .mask {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 45%);
      background: url('~@/assets/bg_score_v.png') bottom center no-repeat;
      background-size: auto 85%;
      background: #FFFFFF;
      width: 8.5rem;
      height: 8.5rem;
      border-radius: 50%;
      box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
    }
    .score {
      position: absolute;
      bottom: 0.5rem;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 0.8rem;
      font-weight: 500;

      strong{
        font-size: 1.8rem;
      }
    }
  }

/* '#377EEA','#FAC15C','#11C16F','#ED5A54','#54BFED',*/
  .box-progress{
    margin-top: 1rem;
    padding: 0.5rem 0;
    border-top: 1px #EFEFEF solid;
    .score {
      display: flex;
      margin: 0.5rem 0;

      .c0, .c5 {
        div.el-progress__text {
          color: #377EEA;
        }
      }
      .c1, .c6 {
        div.el-progress__text {
          color: #FAC15C;
        }
      }
      .c2, .c7 {
        div.el-progress__text {
          color: #11C16F;
        }
      }
      .c3, .c8 {
        div.el-progress__text {
          color: #ED5A54;
        }
      }
      .c4, .c9 {
        div.el-progress__text {
          color: #54BFED;
        }
      }

      .circle {
        div.el-progress__text {
          font-size: 0.5rem !important;
        }
      }
      .line{
        flex: 1;
        padding-left: 0.5rem;
        .title {
          font-size: 0.6rem;
          font-weight: 400;
          color: #000;
        }

        .el-progress-bar {
          padding-right: 0;
        }
        .el-progress__text {
          position: absolute;
          top: -16px;
          right: 0;
        }
      }
    }
  }
.box-hit {
  .title {
    padding-left: 0.8rem;
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  .sec {
    padding: 0.8rem;
  }
  .box-item {
    margin-bottom: 1rem;
    .box-list {
      margin-top: 0.5rem;
      &:first-child {
        margin-top: 0;
      }
      .sub-title {
        font-size: 0.7rem;
        color: #000;
        font-weight: 500;

        .i-check {
          width: 0.7rem;
          height: 0.7rem;
          vertical-align: middle;
        }
      }
    }
  }
  .box-tag {
    display: block;
    .tag{
      display: inline-block;
      margin: 0.5rem 0.5rem 0 0;
      padding: 0.1rem 0.5rem;
      background: #F5F5F5;
      border-radius: 4px;
      position: relative;
      font-size: 0.7rem;
      color: #222;

      &.sel {
        background: #62A0FF;
        color: #FFFFFF;
        border: 1px solid #62A0FF;
      }
      &.btn {
        background: #FFFFFF;
        color: #586679;
        border: 1px solid rgba(0,0,0,0.1);
      }
      &.default{
        .i-check {
          width: 0.7rem;
          height: 0.7rem;
          vertical-align: middle;
        }
      }
    }
  }
}
  
  .box-top {
    height: 3rem;
    .fixed {
      z-index: 1000;
      background: url('~@/assets/bg_main.png') top center no-repeat;
      background-size: 100% auto;
      position: fixed;
      top: 0;
      width: 100%;
      max-width: 750px;
    }

    .top-bar {
      text-align: center;
      font-size: 0.8rem;
      font-weight: 400;
      padding: 1rem 0;

      a {
        position: absolute;
        left: 0.5rem;
        top: 1rem;
      }
    }
  }

  .box-tab {
    padding: 1rem 0.5rem;
    display: flex;
    font-weight: 400;
    font-size: 0.7rem;
    span{
      display: inline-block;
      flex: 1;
      text-align: center;
      height: 1rem;

      i {
        display: block;
        width: 2.5rem;
        height: 3px;
        margin: 0.1rem auto;
        border-radius: 2px;
      }
    }
    span.sel{
      font-size: 0.8rem;
      font-weight: 500;

      i {
        background: #377EEA;
      }
    }
  }
}
</style>
