<template>
  <div class="page-reception-show bg-main">
    <div class="box-top">
      <div class="fixed">
        <div class="box-tab">
          <span v-for="(item,idx) in getTabs" :key="idx"
            :class="item.id==curTab ? 'sel' : ''"
             @click="onChangeTab(item.id)"
                >{{item.title}}<i></i></span>
        </div>
      </div>
    </div>

      <ReceptionAi v-if="curTab=='t0'" />
      <StepScore v-if="curTab=='t1'" />
      <TextRecord v-if="curTab=='t2'" />
      <CustomFile v-if="curTab=='t3'" />
      <DealStep v-if="curTab=='t4'" />
  </div>    
</template>

<script>
import {getRole} from '@/utils/getRole'
import { postForm } from '@/api'
import StepScore from './stepScore.vue';
import TextRecord from './textRecord.vue';
import DealStep from './dealStep.vue';
import CustomFile from './customFile.vue';
import ReceptionAi from './receptionAi.vue';
const color = ['#377EEA','#FAC15C','#11C16F','#ED5A54','#54BFED', '#377EEA','#FAC15C','#11C16F','#ED5A54','#54BFED']

export default {
  data() {
      return {
        color,
        id: '',
        curTab: 't1',
        detail: {},
        checkResult: [],
        score: 0,
        rules: [...new Array(10)]
      }
  },
  computed: {
    getTabs() {
      if (this.isVolvo) {
        return [
          {title: '环节评定', id: 't1'},
          {title: '语音复盘', id: 't2'},
          {title: '客户档案', id: 't3'},
        ];
      }
      return [
        {title: '接待要点', id: 't0'},
        {title: '环节评定', id: 't1'},
        {title: '语音复盘', id: 't2'},
        {title: '客户档案', id: 't3'},
        {title: '成交阶段', id: 't4'},
      ];
    }
  },
  components: {
    StepScore,
    TextRecord,
    DealStep,
    CustomFile,
    ReceptionAi,
  },
  methods: {
    onChangeTab(id) {
      if (id == this.curTab) {
        return;
      }
      this.curTab = id;
      this.$router.replace({name: 'receptionShow', query: {id: this.id, tab: id}});
    },
  },
  created() {
    this.isVolvo = getRole().isVolvo;

    const {id, tab} = this.$router.currentRoute.query || {};
    this.id = id;
    this.curTab = tab || 't1';
    postForm('/h5/seller/detail', {id}).then(res => {
        if (res.status === 1) {
          const {checkResultList, userInfo={}} = res.data;
          if (userInfo.business == '试乘试驾') {
            document.title = '试驾详情';
          } else {
            document.title = '接待详情';
          }
        } else {
          this.$message.error(res.msg);
        }
      });
  }
}
</script>

<style lang="less">

.page-reception-show {
  .box-top {
    height: 3rem;
    .fixed {
      z-index: 1000;
      background: url('~@/assets/bg_main.png') top center no-repeat;
      background-size: 100% auto;
      position: fixed;
      top: 0;
      width: 100%;
      max-width: 750px;
    }

    .top-bar {
      text-align: center;
      font-size: 0.8rem;
      font-weight: 400;
      padding: 1rem 0;

      a {
        position: absolute;
        left: 0.5rem;
        top: 1rem;
      }
    }
  }

  .box-tab {
    padding: 1rem 0.5rem;
    display: flex;
    font-weight: 400;
    font-size: 0.7rem;
    span{
      display: inline-block;
      flex: 1;
      text-align: center;
      height: 1rem;

      i {
        display: block;
        width: 2.5rem;
        height: 3px;
        margin: 0.1rem auto;
        border-radius: 2px;
      }
    }
    span.sel{
      font-size: 0.8rem;
      font-weight: 500;

      i {
        background: #377EEA;
      }
    }
  }
}
</style>
