<template>
  <div></div>  
</template>

<script>
import Vue from 'vue'
import role from '@/utils/getRole'
import { postForm } from '@/api'

export default {
  methods: {
    getBoardInfo() {
      /*
      const data = Vue.ls.get('BOARD_INFO');
      if (data) {
        this.jumpToBoard(data);
        return;
      }
      */
      postForm('/api/admin/getH5UserBoardInfo').then(res => {
        if (res.status !== 1) {
          return;
        }
        Vue.ls.set('BOARD_INFO', res.data);
        this.jumpToBoard(res.data);
      });
    },
    jumpToBoard(data) {
        Vue.ls.set('SHOW_OPEN_TIP', true);
        const {id, level:l} = data || Vue.ls.get('BOARD_INFO') || {};
        let level = l;
        if (this.$route.name == 'afBoardIndex') {
          const map = {
            saNation: 'saNation',
            saRegion: 'saRegion',
            saArea: 'saArea',
            nation: 'saNation',
            region: 'saRegion',
            area: 'saArea',
          };
          level = map[l];
        }
        if (level == 'saNation') {
          this.$router.push({name: 'afCountry', query: {brand_id: id}});
          return;
        }
        if (level == 'saRegion') {
          this.$router.push({name: 'afRegion', query: {region_id: id}});
          return;
        }
        if (level == 'saArea') {
          this.$router.push({name: 'afArea', query: {area_id: id}});
          return;
        }
        if (level == 'nation') {
          this.$router.push({name: 'BoardCountry', query: {brand_id: id}});
          return;
        }
        if (level == 'region') {
          this.$router.push({name: 'BoardRegion', query: {region_id: id}});
          return;
        }
        if (level == 'area') {
          this.$router.push({name: 'BoardArea', query: {area_id: id}});
          return;
        }
    },
  },
  created() {
    this.getBoardInfo();
  }
}
</script>

