<template>
  <div id="home" class="dashboard">
    <div class="box-top" v-if="role.isAfOwnerBoth && role.isReport">
      <div class="fixed">
        <div class="box-jump" @click="$router.push({name: 'afCountry'})">售后平台
          <img src="@/assets/i_change.png"/>
        </div>
      </div>
    </div>
    <div class="box-info-temp">通知:{{boardTip}}</div>
    <div style="height: 0.26rem"></div>
    <div class="box-filter">
      <div class="row">
        <div class="item">
          <el-select v-model="curKey" placeholder="日期" @change="changeCurrent">
              <el-option
                v-for="item in filterOpts"
                :key="item.key"
                :label="item.label"
                :value="item.key"
              ></el-option>
            </el-select>
        </div>
        <div class="item">
          <el-select v-model="query.in_num"
            :clearable="(optList.entryStateOpts || []).length>1"
            placeholder="进店情况" @change="handleChange">
              <el-option
                v-for="item in optList.entryStateOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
        </div>
        <div v-if="!isShowStore" class="item" style="text-align: center;">
          <span :style="`color: ${showToStore ? '#0070C0': '#000'}`"
             @click="showToStore=!showToStore"> 直达门店 </span>
        </div>
          <!--
        <div class="item">
          <el-select v-model.number="query.brand_id"
            :clearable="(optList.brandOpts || []).length>1"
            :placeholder="brandName" @change="handleChange">
              <el-option
                v-for="item in optList.brandOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
          </el-select>
        </div>
          -->
        <img src="@/assets/i_filter.png" style="height: 0.64rem; margin-right: 0.26rem;"  @click="popFilter"/>
      </div>
      <div class="custom" v-if="dateSel">
        <div class="date">
        <el-date-picker
          @change="changePicker"
          v-model="query.start_date"
          type="date"
          align="center"
          prefix-icon=" "
          value-format="yyyy-MM-dd"
          placeholder="开始日期">
        </el-date-picker>
        <span>至</span>
        <el-date-picker
          @change="changePicker"
          v-model="query.end_date"
          type="date"
          align="center"
          prefix-icon=" "
          value-format="yyyy-MM-dd"
          placeholder="结束日期">
        </el-date-picker>
        </div>
      </div>
      <div v-if="showToStore">
        <div class="box-jumpto-store">
          <span>门店代码</span>
          <el-select v-model="company_id" filterable ref="select"
            :clearable="true"
            @visible-change="onSelInput"
            @hook:mounted="onSelInput"
            placeholder="请输入门店代码或店名">
              <el-option v-for="item in companyOpts"
                :key="item.id"
                :value="item.id"
                :label="item.name">
              </el-option>
          </el-select>
          <a class="btn" @click="jumpToStore">去门店</a>
        </div>
        <div v-if="$route.name != 'BoardCountry'" class="line"></div>
      </div>
    </div>

    <el-drawer
      title=" "
      size="100%"
      custom-class="pop-filter"
      :visible.sync="showFilter"
      :with-header="false">
      <div style="font-size: 0.6rem; text-align: right; padding: 0.2rem 0.25rem; 0 0">
      <i class="el-icon-close" @click="hideFilter"></i>
      </div>
      <div class="box-search">
        <div class="item">
          <div class="label">日期</div>
          <div style="display: flex;">
            <div style="flex: 1;">
            <el-select v-model="curKey" placeholder="日期" @change="changeCurrentF">
              <el-option
                v-for="item in filterOpts"
                :key="item.key"
                :label="item.label"
                :value="item.key"
              ></el-option>
            </el-select>
            </div>
            <div class="custom" v-if="dateSel">
            <div class="date">
              <el-date-picker
                v-model="query.start_date"
                type="date"
                align="center"
                prefix-icon=" "
                value-format="yyyy-MM-dd"
                placeholder="开始日期">
              </el-date-picker>
              <span>至</span>
              <el-date-picker
                v-model="query.end_date"
                type="date"
                align="center"
                prefix-icon=" "
                value-format="yyyy-MM-dd"
                placeholder="结束日期">
              </el-date-picker>
            </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="label">进店情况</div>
          <el-radio-group v-model="query.in_num" class="group-btn">
            <el-radio v-for="(text, idx) in optList.entryStateOpts"
              :key="idx" :label="text.name" border class=""></el-radio>
          </el-radio-group>
        </div>

        <div class="item">
          <div class="label">试乘试驾</div>
          <el-radio-group v-model="query.is_this_test_drive" class="group-btn">
            <el-radio v-for="(text, idx) in optList.driveOpts"
              :key="idx" :label="text.name" border class=""></el-radio>
          </el-radio-group>
        </div>
        <div class="item" v-if="isShowDealerGroup">
          <div class="label">经销商集团</div>
          <el-select v-model.number="query.dealer_group_id" filterable
            :clearable="(optList.dealerGroupOtps || []).length>1"
            placeholder="请选择经销商集团" @change="changeDealerGroup">
              <el-option
                v-for="item in optList.dealerGroupOtps"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
          </el-select>
        </div>
      <fragment v-if="isVendor">
        <div class="item" v-if="(optList.brandOpts || []).length>1">
          <div class="label">{{brandName}}</div>
          <el-select v-model.number="query.brand_id"
            @change="changeBrand"
            :clearable="(optList.brandOpts || []).length>1"
            :placeholder="'请选择' + brandName">
              <el-option
                v-for="item in optList.brandOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
          </el-select>
        </div>
        <div class="item" v-if="isShowComType">
          <div class="label">门店类型</div>
          <el-select v-model.number="query.company_type_id"
            :clearable="(optList.companyTypeOtps || []).length>1"
            placeholder="请选择门店类型" @change="changeCompanyType">
            <el-option
              v-for="item in optList.companyTypeOtps"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select >
        </div>
      </fragment>

      <fragment class="box-org" v-if="isSuper">
        <div class="item">
          <div class="label">集团名称</div>
          <el-select v-model.number="query.crop_id"
            :clearable="(optList.cropOpts || []).length>1"
            placeholder="请选择集团名称" @change="changeCrop">
              <el-option
                v-for="item in optList.cropOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
        </div>

        <div class="item" v-if="isShowDealerGroup">
          <div class="label">经销商集团</div>
          <el-select v-model.number="query.dealer_group_id" filterable
            :clearable="(optList.dealerGroupOtps || []).length>1"
            placeholder="请选择经销商集团" @change="changeDealerGroup">
              <el-option
                v-for="item in optList.dealerGroupOtps"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
          </el-select>
        </div>

        <div class="item">
          <div class="label">{{brandName}}</div>
          <el-select v-model.number="query.brand_id"
            @change="changeBrand"
            :clearable="(optList.brandOpts || []).length>1"
            :placeholder="brandName">
              <el-option
                v-for="item in optList.brandOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
          </el-select>
        </div>
        <div class="item" v-if="isShowRegion">
          <div class="label">大区名称</div>
          <el-select v-model.number="query.region_id"
            :clearable="(optList.regionOpts || []).length>1"
            placeholder="请选择大区名称" @change="changeRegion">
              <el-option
                v-for="item in optList.regionOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
          </el-select>
        </div>
        <div class="item" v-if="isShowArea">
          <div class="label">小区名称</div>
          <el-select v-model.number="query.area_id"
            :clearable="(optList.areaOpts || []).length>1"
            placeholder="请选择小区名称" @change="changeArea">
            <el-option
              v-for="item in optList.areaOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select >
        </div>
        <div class="item" v-if="isShowComType">
          <div class="label">门店类型</div>
          <el-select v-model.number="query.company_type_id"
            :clearable="(optList.companyTypeOtps || []).length>1"
            placeholder="请选择门店类型" @change="changeCompanyType">
            <el-option
              v-for="item in optList.companyTypeOtps"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select >
        </div>
        <div class="item"  v-if="isShowStore">
          <div class="label">公司名称</div>
          <el-select v-model.number="query.company_id" filterable
            :clearable="(optList.companyOpts || []).length>1"
            placeholder="请选择公司名称">
              <el-option
                v-for="item in optList.companyOpts"
                :key="item.id"
                :label="String(item.code).toUpperCase() + item.name"
                :value="item.id"
              ></el-option>
          </el-select>
        </div>
      </fragment>
        <div class="box-btn">
          <span @click="resetQuery" class="reset">重置</span>
          <span @click="searchData" class="search">查询</span>
        </div>
      </div>
    </el-drawer>
    <div style="padding: 0.4rem;">
    <router-view ref="child" :key="this.$route.path"/>
    </div>
    <NavBar tab="统计分析"></NavBar>
  </div>
</template>
<script>
import Vue from 'vue'
import { getOptions } from "@/dbCommon/api/common";
import { getData } from "@/dbCommon/api";
import { getFormatDate } from "@/dbCommon/utils";
import Menu from "@/dbCommon/utils/menu.js";
import {getCookie} from "@/dbCommon/utils/cookie.js";
import {jump} from "@/dbCommon/utils/index"
import util from "@/dbCommon/utils/index";
import NavBar from '@/components/navbarAdmin.vue';
import {getRole} from '@/utils/getRole';


function StringToNum(obj){
  for(let i in obj){
    if(i!='start_date'&&i!="end_date"){
      obj[i] = (isNaN(Number(obj[i])) || !obj[i]) ? obj[i] : Number(obj[i]);
    }

  }
    return obj
}

export default {
  data() {

    return {
      role: getRole(),
      company_id: '',
      companyOpts: [],
      showToStore: false,
      isSuper: false,
      isVendor: false,
      optList: {},
      query: {},
      filterOpts: [
        { key: "month", label: "本月" },
        { key: "week", label: "本周" },
        { key: "yesterday", label: "本日" },
        { key: "lastMonth", label: "上月" },
        { key: "custom", label: "自定义" },
      ],
      entryStateOpts: [
        { key: 0, value: '首次进店', label: '首次进店'},
        { key: 1, value: '再次进店', label: '再次进店'},
      ],
      value: "",
      content:"",
      isShow: true,
      curKey: "yesterday",
      isShowDealerGroup: false,
      isShowRegion: true,
      isShowArea: true,
      isShowComType: true,
      isShowStore: true,
      dateSel: false,
      showFilter: false,
      brandName: '品牌名称',
      boardTip: '由于Q1模型变更所导致的系统升级，4S&卫星店【全国看板】-【接待质量得分】部分功能暂时无法使用（DTS模型不变不受影响）。新看板预计将于1月底上线，在此期间除【全国看板】-【接待质量得分】外的其他后台功能（包括系统使用得分）均可正常使用，还请知悉。',
    };
  },
  computed: {
    
  },
  components:{
    NavBar,
  },
  watch: {
    $route: {
      handler() {
        this.showToStore = false;
        this.isShowDealerGroup = false;
        if (Menu.RegionHidden.includes(this.$route.name)) {
          this.isShowRegion = false;
        } else {
          this.isShowRegion = true;
        }
         if (Menu.AreaHidden.includes(this.$route.name)) {
          this.isShowArea = false;
        } else {
          this.isShowArea = true;
        }
         if (Menu.storeHidden.includes(this.$route.name)) {
          this.isShowStore = false;
        } else {
          this.isShowStore = true;
        }
    
        const {region_id, area_id, company_id, ...rest} = this.$store.state.queryDB || {};
        const brandId = Number(getCookie("brandId")) || '';
        const cropId = Number(getCookie("cropId")) || '';
        this.query={
          ...rest,
          crop_id: cropId,
          brand_id: brandId,
          in_num: '首次进店',
          ...StringToNum(this.$route.query)
        };
        this.setDefaultValue();
        if (this.showFilter) {
          this.hideFilter();
        }
        this.$store.commit("SET_DB_QUERY", this.query);
      },

      immediate: true,
    },
  },
  async created() {
    /*
    const showOpenTip = Vue.ls.get('SHOW_OPEN_TIP');
    if (showOpenTip) {
      this.$alert(this.boardTip, '通知', {
        customClass: 'box-alert',
        showClose: false,
        confirmButtonText: '知道了',
        callback: action => {
          Vue.ls.remove('SHOW_OPEN_TIP');
        }
      });
    }
    */

    const adminId = getCookie("adminId");
    this.isSuper = [-1].indexOf(Number(adminId)) >= 0;
    this.isVendor = [1].indexOf(Number(adminId)) >= 0;

    const {start_date: s, end_date: e} = this.$router.currentRoute.query || {};

    this.curKey = 'yesterday';
    const { start_date, end_date } = getFormatDate(this.curKey);

    const isYestoday = start_date == s && end_date == e;
    if((!s && !e) || isYestoday) {
      this.query = {
        ...this.query,
        start_date,
        end_date,
      }
      this.$store.commit("SET_DB_QUERY", this.query);
    } else {
      this.curKey = 'custom';
      this.dateSel = true;
      this.query = {
        ...this.query,
        start_date: s,
        end_date: e,
      }
    }


    this.loadComList();
    this.init();
  },
  methods: {
    jumpToStore() {
      const query={...this.$store.state.queryDB,company_id:this.company_id};
      jump("/dashboard/store",this, query);
    },
    onSelInput() {
      this.$nextTick(() => {
          const {select} = this.$refs;
          const input = select.$el.querySelector('.el-input__inner');
          input.removeAttribute('readonly');
      });
    },
    hideFilter() {
      this.showFilter = false;
      this.query = {...this.tempQ};
      this.curKey = this.tempD;
    },
    popFilter() {
      this.showFilter = true;
      this.tempQ = {...this.query};
      this.tempD = this.curKey;
      this.dateSel = this.curKey == 'custom';
    },
    setDefaultValue() {
      const {cropOpts, brandOpts,
        regionOpts, areaOpts, companyOpts} = this.optList;

      const q = [
        {key: 'crop_id', arr: cropOpts},
        {key: 'brand_id', arr: brandOpts},
      ];
      if (this.isShowRegion) {
        q.push({key: 'region_id', arr: regionOpts});
      }
      if (this.isShowArea) {
        q.push({key: 'area_id', arr: areaOpts});
      }
      /*
      if (this.isShowStore) {
        q.push({key: 'company_id', arr: companyOpts});
      }
      */
      this.query = {...this.query, ...setDefaultValue(q)};
    },
    async init() {
      const {crop_id, brand_id, region_id, area_id, dealer_group_id, company_type_id} = this.query;
      const { data: cropOpts } = await getOptions({ table: "crop" });
      const { data: dealerGroupOtps } = await getOptions({ table: "dealer_group", crop_id });
      const { data: brandOpts } = await getOptions({ table: "brand", crop_id, dealer_group_id });
      const { data: regionOpts } = await getOptions({ table: "region", crop_id, dealer_group_id, brand_id });
      const { data: areaOpts } = await getOptions({ table: "area", crop_id, dealer_group_id, brand_id, region_id });
      const { data: companyTypeOtps} = await getData('/api/option/getCompanyTypeList', {crop_id, dealer_group_id, brand_id, region_id, area_id});
      const { data: companyOpts } = await getOptions({ table: "company", crop_id, dealer_group_id, brand_id, region_id, area_id, company_type_id  });
      const { data: entryStateOpts} = await getData('/api/option/in_num', {business: '展厅接待'});
      const { data: driveOpts} = await getData('/api/option/is_this_test_drive');
      this.optList = {
        cropOpts,
        dealerGroupOtps,
        brandOpts,
        regionOpts,
        areaOpts,
        companyTypeOtps,
        companyOpts,
        entryStateOpts,
        driveOpts,
      };

      this.setDefaultValue();
    },
    changePicker() {
      this.searchData();
    },
    async changeCrop(item) {
      this.query = {
        ...this.query,
        dealer_group_id: "",
        region_id: "",
        area_id: "",
        company_id: "",
        brand_id: "",
        company_type_id: "",
      };
      const { data: dealerGroupOtps = [] } = await getOptions({
        table: "dealer_group",
        crop_id: item,
      });
      const { data: brandOpts = [] } = await getOptions({
        table: "brand",
        crop_id: item,
      });
      const { data: regionOpts = [] } = await getOptions({
        table: "region",
        crop_id: item,
      });
      const { data: areaOpts = [] } = await getOptions({
        table: "area",
        crop_id: item,
      });
      const { data: companyOpts = [] } = await getOptions({
        table: "company",
        crop_id: item,
      });
      const { data: companyTypeOtps = []} = await getData('/api/option/getCompanyTypeList', {
        crop_id: item,
      });
      this.optList = {
        ...this.optList,
        dealerGroupOtps,
        brandOpts,
        regionOpts,
        areaOpts,
        companyOpts,
        companyTypeOtps,
      };
    },
    async changeDealerGroup(item) {
      this.query = {
        ...this.query,
        region_id: "",
        area_id: "",
        company_id: "",
        brand_id: "",
        company_type_id: "",
      };
      const { data: brandOpts = [] } = await getOptions({
        table: "brand",
        dealer_group_id: item,
      });
      const { data: companyOpts = [] } = await getOptions({
        table: "company",
        dealer_group_id: item,
      });
      const { data: companyTypeOtps = []} = await getData('/api/option/getCompanyTypeList', {
        crop_id: this.query.crop_id,
        dealer_group_id: item,
      });
      this.optList = {
        ...this.optList,
        brandOpts,
        companyOpts,
        companyTypeOtps,
      };
    },
    async changeBrand(item) {
      this.query = {
        ...this.query,
        company_id: "",
        company_type_id: "",
      };
      const { data: companyOpts = [] } = await getOptions({
        table: "company",
        crop_id: this.query.crop_id,
        dealer_group_id: this.query.dealer_group_id,
        brand_id: item,
      });
      const { data: companyTypeOtps = []} = await getData('/api/option/getCompanyTypeList', {
        crop_id: this.query.crop_id,
        dealer_group_id: this.query.dealer_group_id,
        brand_id: item,
      });
      this.optList = {
        ...this.optList,
        companyOpts,
        companyTypeOtps,
      };
    },
    async changeRegion(item) {
      this.query = {
        ...this.query,
        area_id: "",
        company_id: "",
        company_type_id: "",
      };
      const { data: areaOpts = [] } = await getOptions({
        table: "area",
        region_id: item,
      });
      const { data: companyOpts = [] } = await getOptions({
        table: "company",
        region_id: item,
      });
      const { data: companyTypeOtps = []} = await getData('/api/option/getCompanyTypeList', {
        crop_id: this.query.crop_id,
        dealer_group_id: this.query.dealer_group_id,
        brand_id: this.query.brand_id,
        region_id: item,
      });
      this.optList = {
        ...this.optList,
        areaOpts,
        companyOpts,
        companyTypeOtps,
      };
    },
    async changeArea(item) {
      this.query = {
        ...this.query,
        company_id: "",
        company_type_id: "",
      };
      const { data: companyOpts = [] } = await getOptions({
        table: "company",
        area_id: item,
      });
      const { data: companyTypeOtps = []} = await getData('/api/option/getCompanyTypeList', {
        crop_id: this.query.crop_id,
        dealer_group_id: this.query.dealer_group_id,
        brand_id: this.query.brand_id,
        region_id: this.query.region_id,
        area_id: item,
      });
      this.optList = {
        ...this.optList,
        companyOpts,
        companyTypeOtps,
      };
    },
    async changeCompanyType(item) {
      if (!this.isShowStore) {
        return;
      }
      this.query = {
        ...this.query,
        company_id: "",
      };
      const { data: companyOpts = [] } = await getOptions({
        table: "company",
        crop_id: this.query.crop_id,
        dealer_group_id: this.query.dealer_group_id,
        brand_id: this.query.brand_id,
        region_id: this.query.region_id,
        area_id: this.query.area_id,
        company_type_id: item,
      });
      this.optList = {
        ...this.optList,
        companyOpts,
      };
    },
    changeCurrentF(key) {
      if (key == 'custom') {
        this.dateSel = true;
        this.query = {
          ...this.query,
          start_date: '',
          end_date: '',
        };
        return;
      }
      this.dateSel = false;
      const { start_date, end_date } = getFormatDate(key);
      this.query = {
        ...this.query,
        start_date,
        end_date,
      };
    },
    changeCurrent(key) {
      if (key == 'custom') {
        this.dateSel = true;
        this.query = {
          ...this.query,
          start_date: '',
          end_date: '',
        };
        return;
      }
      this.dateSel = false;
      const { start_date, end_date } = getFormatDate(key);
      this.query = {
        ...this.query,
        start_date,
        end_date,
      };
      this.searchData();
    },
    jumpToUser(){
      this.$router.push({name: 'member'});
    },
    resetQuery() {
      const {entryStateOpts, driveOpts} = this.optList;
      this.query = {};
      this.optList = {entryStateOpts, driveOpts};
      this.loadComList();
      this.init();
    },
    handleChange(v) {
      this.searchData();
    },
    searchData() {
      this.showFilter = false;
      this.$store.commit("SET_DB_QUERY", this.query);
      this.$refs.child.getDataBySearch();
    },

    async loadComList() {
      const { data: companyOpts } = await getData('/api/option/index', { table: "company", auth:1});
      this.companyOpts = (companyOpts || []).map(o => ({
        ...o,
        name: String(o.code).toUpperCase() + o.name,
      }));
    },
  },
};

function setDefaultValue(list) {
  return list.reduce((s,o) => {
    if ((o.arr || []).length == 1) {
      return {
        ...s,
        [o.key]: o.arr[0].id
      }
    } else {
      return s;
    }
  }, {});
}
</script>
<style lang="less" scope>
  .box-info-temp {
    display: none;
    color: #f00;
    text-align: left;
    padding: 0.4rem;
    background: #fff;
  }
  .box-alert {
    width: 80%;
  }

.pop-filter {
  .el-drawer__header {
    background: #F7FAFD;
    margin: 0;
    padding: 0.2rem 0.23rem 0 0;
  }
  .el-drawer__body {
    background: #F7FAFD;
  }
}
.detail {
    flex: 1;
    display: flex;
 
    justify-content: space-around;
    flex-wrap: wrap;

    padding: 0px 0.685185rem /* 37/54 */;
    align-items: center;
    .itemDetail {
      height: 1.388889rem /* 75/54 */;
    display: flex;
    flex-direction: column;
    align-items: center;
      .label {
        color: #fff;
        font-size: .296296rem /* 16/54 */;
        padding-left: 0.833333rem /* 45/54 */;
        white-space: nowrap;
        &.icon_1 {
          background: url(~@/assets/icon_1.png) no-repeat;
          background-size: 0.703704rem /* 38/54 */ 0.351852rem /* 19/54 */;
          background-position: center left;
        }
        &.icon_2 {
          background: url(~@/assets/icon_2.png) no-repeat;
          background-size: 0.703704rem /* 38/54 */ 0.351852rem /* 19/54 */;
          background-position: center left;
        }
        &.icon_3 {
          background: url(~@/assets/icon_3.png) no-repeat;
          background-size: 0.703704rem /* 38/54 */ 0.351852rem /* 19/54 */;
          background-position: center left;
        }
        &.icon_4 {
          background: url(~@/assets/icon_4.png) no-repeat;
          background-size: 0.703704rem /* 38/54 */ 0.351852rem /* 19/54 */;
          background-position: center left;
        }
        &.icon_5 {
          background: url(~@/assets/icon_5.png) no-repeat;
          background-size: 0.703704rem /* 38/54 */ 0.351852rem /* 19/54 */;
          background-position: center left;
        }
      }
    }
  }
  .rank_list {
    color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    height: 100%;
    li {
      display: flex;
      align-items: center;
      .rank_no {
        width: 0.555556rem /* 30/54 */;
        height: 0.555556rem /* 30/54 */;
        border-radius: 0.555556rem /* 30/54 */;
        margin-right: 0.277778rem /* 15/54 */;
        background: #340a5b;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        b {
          width: 0.351852rem /* 19/54 */;
          height: 0.351852rem /* 19/54 */;
          display: inline-block;
          background: #08b9e1;
          border-radius: 100%;
          text-align: center;
          line-height: 0.351852rem /* 19/54 */;
        }
      }
      .rank_name {
        width: 1.407407rem /* 76/54 */;
        width: 2.962963rem /* 160/54 */;
        white-space: nowrap;
        text-align: left;
      }
      .rank_bg {
        background: #023281;
        width: 3.814815rem /* 206/54 */;
        height: 0.333333rem /* 18/54 */;
        border-radius: 0.333333rem /* 18/54 */;
      }
      .rank_num {
        width: 1.037037rem /* 56/54 */;
        padding-left: 0.277778rem /* 15/54 */;
      }
      .rank_bg .rank_pecent {
        background: #08b9e1;
        height: 0.333333rem /* 18/54 */;
        display: inline-block;
        border-radius: 0.333333rem /* 18/54 */;
      }
      &.rank_1 {
        .rank_no {
          background: #340a5b;
          b {
            background: #ce0c64;
          }
        }
        .rank_bg {
          background: #340a5b;
          .rank_pecent {
            background: #ce0c64;
          }
        }
      }
      &.rank_2 {
        .rank_no {
          background: #38204e;
          b {
            background: #d66d2a;
          }
        }
        .rank_bg {
          background: #38204e;
          .rank_pecent {
            background: #d66d2a;
          }
        }
      }
      &.rank_3 {
        .rank_no {
          background: #003f67;
          b {
            background: #00d8ad;
          }
        }
        .rank_bg {
          background: #003f67;
          .rank_pecent {
            background: #00d8ad;
          }
        }
      }
    }
    .table {
      width: 100%;
      color:#fff;
      text-align: center;
      tr {
        margin-bottom: 3px;
      }
      tr th {
        color: #00d5e9;
      }
      tr th,
      tr td {
        height: 0.592593rem /* 32/54 */;
     
        border: 1px solid transparent;
        span{
         
          width: .37037rem /* 20/54 */;
          position: relative;
          display: inline-block;
          padding-bottom:3px;
          &.moreInfo{
             
            border-bottom:1px solid #fff;
          }
        }
      }
    }
  }

  .el-scrollbar {
    > .el-scrollbar__bar {
    opacity: 1 !important;
    }
  }


.el-select-dropdown {
  .el-select-dropdown__item {
    max-width: 9rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.box-jumpto-store {
  margin-top: 0.32rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 0.37333rem;
  .btn {
    background: #0070C0;
    color: #fff;
    border-radius: 2px;
    display: inline-block;
    border: 0;
    line-height: 1rem;
    height: 1rem;
    padding: 0 0.48rem;
  }
  .el-select {
    flex: 1;
    margin: 0 0.26666rem;
    .el-input__inner, .el-input__icon {
      border: 0;
      border-radius: 2px;
      background: #E5E8EE;
      line-height: 1rem;
      height: 1rem;
    }
  }
}

.dashboard {
  font-size: 0.32rem;
  min-height: 100vh;
  padding-bottom: 1.5rem;
  background: linear-gradient(180deg, #E6EEF9 2%, #FFFFFF 94%);
  background-size: 100% 5.33333rem;
  background-repeat: no-repeat;
  background-color: #FFFFFF;
  max-width: 750px;
  margin: auto;

.box-top {
  height: 44px;
  position: relative;

  .fixed {
    height: 44px;
    line-height: 44px;
    top: 0;
    width: 100%;
    max-width: 750px;
    z-index: 1000;
    position: fixed;
    background: linear-gradient(180deg, #E6EEF9 2%, #FFFFFF 94%);
    background-size: 100% 5.33333rem;
  }

  .box-jump {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #377EEA;
    width: 130px;
    margin: auto;

    img {
      height: 15px;
    }
  }
}
.box-search, .box-filter {
  .item {
    overflow: hidden;
  }

  .el-select {
    width: 100%;
  }
  .el-input {
    padding-right: 0.3rem;
    border-radius: 2px;
  }

  .item, .custom {
    .el-input__inner {
      text-align: center;
      background-color: transparent;
      border: 0;
      padding: 0 0.18666rem;
      color: #4F5154;
      height: 1.06666rem;
      line-height: 1.06666rem;
      font-size: 0.37333rem;
      font-weight: 500;
      &::-webkit-input-placeholder{
        color: #aaa; 
        font-size: 0.37333rem;
        font-weight: 500;
      }
    }
  }

  .date input.el-input__inner {
  }
  .el-select .el-input .el-select__caret {
      color: #929498;
  }
}
  .box-search {
      color: #4F5154;
      font-weight: 500;
      font-size: 0.37333rem;
      padding: 0rem 0 1.5rem 0;
      .item {
        padding: 0 0.5rem;
        margin-bottom: 0.26666rem;
        overflow: hidden;
        border-radius: 2px;

        .el-input {
          background: #E5E8EE;
        }

        >.label {
          margin: 0.1rem 0 0.2rem 0;
        }
      }

      .group-btn {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: -0.18rem;

        .el-radio__input {
          display: none;
        }
        .el-radio.is-bordered {
          border-radius: 2px;
          margin: 0.18rem 0 0 2%;
          &:nth-child(3n+1) {
            margin-left: 0;
          }
        }
        >label {
          flex: 1;
          text-align: center;
          background: #E5E8EE;
          border-color: #E5E8EE;
          font-weight: 400;
          min-width: 32%;
          max-width: 32%;

          &.is-checked {
            background: #fff;
          }
        }
      }
      .custom {
        max-width: 70%;
        margin-left: 0.2rem;
        .date {
          padding-left: 0.8rem;
          display: flex;
          align-items: center;
          background: #E5E8EE url(~@/assets/i_date2.png) no-repeat 0.1rem 0.26666rem;
          background-size: 0.53333rem 0.53333rem;
          .el-input__inner {
            padding: 0;
          }
        }
      }

      .box-btn {
        position: fixed;
        bottom: 0;
        width: 100%;
        max-width: 750px;
        display: flex;
        span {
          font-size: 1em;
          line-height: 1.06666rem;
          flex: 1;
          text-align: center;
          font-weight: 500;
          color: #969799;
          background: #F5F5F5;
          &.search {
            color: #fff;
            background: #377EEA;
          }
        }
      }
  }

  .box-filter {
    margin: 0 0.4rem 0.26666rem 0.4rem;
    .row {
      background: #fff;
      border-radius: 2px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .item {
        flex: 1;
        overflow: hidden;
        margin-right: 2%;
        border-radius: 2px;
        font-size: 0.37333rem;
        font-weight: 500;
      }
      .item-icon {
        padding-right: 0.3rem;
        font-size: 0.5rem;
      }
    }
    .custom {
      background: #E5E8EE;
      margin-top: 0.26666rem;
      width: 100%;
      .date {
        padding-left: 0.8rem;
        display: flex;
        align-items: center;
        background: url(~@/assets/i_date.png) no-repeat 0.5rem 0.26666rem;
        background-size: 0.53333rem 0.53333rem;
        .el-input__inner {
          padding: 0;
        }
      }
    }
  }

  .box-org {
    flex-wrap:wrap;
    justify-content: space-between;
    >div {
      width: 32%;
      margin-bottom: 0.26666rem;
      overflow: hidden;
    }
  }
  .box-date, .box-org {
    color: #4F5154;
    font-weight: 500;
    display: flex;
    font-size: 0.37333rem;

    >div {
      width: 32%;
      margin-bottom: 0.26666rem;
      overflow: hidden;
    }

    .item {
      background: #E5E8EE;
      border-radius: 2px;
    }

    .box-btn {
      display: flex;
      span {
        font-size: 1em;
        height: 1.06666rem;
        line-height: 1.06666rem;
        flex: 1;
        text-align: right;
        font-weight: 500;
        color: #969799;
        &.search {
          color: #377EEA;
        }
      }
    }
  }

  .box-bar-a {
    height: 0.3rem;
    .fix-bar-a {
      padding: 0.26666rem 0;
      p {
        font-size: 0.32rem;
        padding-top: 0.74666rem;
        background-size: auto 0.64rem;
        &:nth-child(1) {
          background-size: auto 0.53333rem;
        }
      }
    }
  }
  .title {
    font-weight: 500;
    color: #000000;
    line-height: 0.50666rem;
    font-size: 0.42666rem;
    margin-bottom: 0.26666rem;
  }


  .line {
    height: 1px;
    background: #C3D5E4;
    margin: 0.53333rem 0;
  }

  .box-total {
    height: 2.66666rem;
    background: #0070C0;
    border-radius: 2px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 0.26666rem;

    p {
      padding: 0;
      margin: 0;
      text-align: center;
      flex: 1;
    }

    span, strong, i {
      display: block;
      line-height: 1.5;
      font-style: normal;
    }

    strong {
      font-size: 2em;
    }
    .a {
      font-size: 3em;
      line-height: 1.1;
    }
    .a-p {
      font-size: 1.2em;
      opacity: 1;
    }

    .flag {
      max-width: 0.5rem;
      font-size: 0.53333rem;
      font-weight: bold;
    }

    .i-question {
      display: inline-block;
      width: 0.4rem;
      height: 0.4rem;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url(~@/assets/i_question.png);
      vertical-align: top;
    }
  }

  .box-core {
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    .main {
      width: 4.4rem;
      height: 2.4rem;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-top: 0.4rem;
      padding-left: 0.32rem;
      padding-top: 0.95rem;
      font-weight: 400;

      &:nth-child(1) {
        margin-top: 0;
        background-image: url(~@/assets/bg_s1.png);
      }
      &:nth-child(2) {
        margin-top: 0;
        background-image: url(~@/assets/bg_s2.png);
      }
      &:nth-child(3) {
        background-image: url(~@/assets/bg_s3.png);
      }
      &:nth-child(4) {
        background-image: url(~@/assets/bg_s4.png);
      }

      .label {
        opacity: 0.64;
      }
      .value {
        strong {
          font-size: 0.53333rem;
        }
      }
    }
  }


.list-top {
  padding-top: 0.1rem;
  max-height: 7.1rem;
  overflow: auto;

  &::-webkit-scrollbar-thumb{
      background-color:#aaa;
      border-radius:10px;
  }
  &::-webkit-scrollbar-track{
      background-color:transparent;
  }
  &::-webkit-scrollbar{
      width:2px;
      height:2px;
      background-color: transparent;
  }

  li {
    margin-top: 0.26666rem;
    display: flex;
    align-items: center;
    .box-bar {
      flex: 1;
      position: relative;
      white-space: nowrap;
      height: 0.21333rem;
      .bara {
        background: #C8C9CC;
      }
      .bar {
        background: #969799;
      }
      span {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        border-radius: 0.21333rem;
      }
    }
    .score {
      margin-left: 0.18666rem;
      display: block;
      width: 0.53333rem;
    }
    .label {
      width: 2.66666rem;
      font-size: 0.26666rem;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      strong {
        color: #fff;
        width: 0.37333rem;
        height: 0.37333rem;
        line-height: 1.25;
        margin-right: 0.13333rem;
        background: #C8C9CC;
        display: inline-block;
        text-align: center;
      }
    }
  }
  li:first-child {
    .label {
      strong {
        background: #ED5A54;
      }
    }
    .bar {
      background: #ED5A54;
    }
  }
  li:nth-child(2) {
    .label {
      strong {
        background: #FAC15C;
      }
    }
    .bar {
      background: #FAC15C;
    }
  }
  li:nth-child(3) {
    .label {
      strong {
        background: #11C16F;
      }
    }
    .bar {
      background: #11C16F;
    }
  }
}


.pop-score {
  .el-dialog {
    width: 94%;

    .el-dialog__body {
      padding: 30px 10px;
    }
  }
}

.pop-formula {
  .el-dialog {
    width: 94%;

    .el-dialog__body {
      padding: 30px 10px;
    }
  }
}
.box-formula {
  font-size: 0.32rem;
  p {
    padding: 0.2rem 0;
    display: flex;
    align-items: center;

    span {
      padding: 2px;
    }

    strong {
      text-align: center;
      display: block;
      line-height: 1.5;
      font-weight: normal;
      &:first-child {
        border-bottom: 1px solid #000;
      }
    }
  }
}

}
.userDiv{
  margin-left: -0.4rem;
  background-color: #0070C0;
  position: fixed;
  bottom:0;
  max-width: 750px;
  width: 100%;
  height: 1.06666rem;
  background-image: url(~@/assets/icon_my.png);
  background-repeat: no-repeat;
  background-position: center 0.14666rem;
  background-size: 0.61333rem;
}
</style>
